import axios from "axios";

import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";

export const getZSMWssNameApi = (sh3Code) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=select id,CustomerName__c,Customer_Code__c,Town__c  from account where   RecordType.Name in ('Customer', 'CAPScouted') AND Cluster_Code__c='${sh3Code}' AND DiscontinueFlag__c = false`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data?.records,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    // if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
    //   sessionExpireTimeout(dispatch);
    // } else {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
    // }
  }
};
