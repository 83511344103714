import React from 'react'
import PowerBi from '../../Pages/PowerBi'
import PageHeader from '../Common/PageHeader'

const TSIMarketWorking = () => {

  return (
    <>
      <PageHeader title="TSI Market Working FTD" path="/" />
      <div className="pbi__outerBox pbi_dashboard">
        <PowerBi
          id="ba696416-1e37-40f8-854e-c6816ef9104c"
          pageName="fea60e84-eaf3-48a4-90ad-795360fb0878"
          filterName="DimHierarchy"
        />
      </div>
    </>
  )
}

export default TSIMarketWorking