import { getUniqueListBy } from "../../Components/Common/Function";
import { getRmdmTownApi } from "../../services/rmdm/getRmdmTownApi";
import { getRmdmWssApi } from "../../services/rmdm/getRmdmWssApi";
import { getClusterCodeApi } from "../../services/zsm/getClusterCodeApi";
import {
  GET_CLUSTER_CODE_FAIL,
  GET_CLUSTER_CODE_SUCCESS,
} from "../constants/action-types";

export const getRMDMClusterCode = (Title, Email) => async (dispatch) => {

  const response = await getClusterCodeApi("", Title, "", "", Email);
  if (response?.success === true) {
    let arr = [];
    const data = getUniqueListBy(response?.data?.records, "CLUSTER_CODE__c");
    data?.map((item) => {
      arr.push(item?.CLUSTER_CODE__c);
    });
    await dispatch(getRmdmTownApi(arr));
    dispatch({
      type: GET_CLUSTER_CODE_SUCCESS,
      payload: response?.data?.records,
    });
  } else {
    dispatch({ type: GET_CLUSTER_CODE_FAIL });
  }
};
