import React from "react";
import { useSelector } from "react-redux";
import { PCMS_URL } from "../../variables";

const PCMSDashboard = () => {

    const pcmsToken = useSelector((state) => state?.pcmsToken?.pcmsAccessToken);

    //---------------------------------------- Get URL With token ----------------------------------------------------- //
    
    const getIframeSrc = () => {
        return `${PCMS_URL}/?token=${pcmsToken}`;
    };

    //---------------------------------------------- End ------------------------------------------------------------- //

    return (
        <>
            <div className="pcms__outerBox">
                <iframe
                    id="embeddedIframe"
                    className="pcms__iframe"
                    src={getIframeSrc()}
                    width="100%"
                    height="500"
                    title="Embedded Iframe"
                ></iframe>
            </div>
        </>
    );
};

export default PCMSDashboard;
