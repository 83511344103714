import React from 'react'
import PowerBi from '../../Pages/PowerBi'
import PageHeader from '../Common/PageHeader'

const KeyDealerPerformance = () => {
    
  return (
    <>
      <PageHeader title="Key Dealer Performance" path="/" />
        <div className="pbi__outerBox pbi_dashboard">
            <PowerBi
              id= "6be342b0-50d4-42b7-952c-85ddb13f31d1"
              pageName= "db41383175308fbbf2bb"
              filterName="SH2Master"
            />
        </div>
    </>
  )
}

export default KeyDealerPerformance