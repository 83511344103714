import React from 'react'
import PowerBi from '../../Pages/PowerBi'
import PageHeader from '../Common/PageHeader'

const DistributorInvoicing = () => {
    
  return (
    <>
        <PageHeader title="Distributor Invoicing FTD" path="/" />
          <div className="pbi__outerBox pbi_dashboard">
            <PowerBi
                id= "152a3955-1279-461a-bd81-da4db6e1d3f7"
                pageName= "fea60e84-eaf3-48a4-90ad-795360fb0878"
                filterName="DimHierarchy"
            />
        </div>
    </>
  )
}

export default DistributorInvoicing