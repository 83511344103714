import axios from "axios";
import { isLocalServer } from "../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../utils/getNodeUrl";


export const loginApi = async ({ username, password }) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const nodeBaseUrl = getNodeServerUrl();

  const options = {
    url: `${nodeBaseUrl}/user/get-user-access-token`,
    method: "post",
    headers: headers,
    data: { username, password },
  };

  try {
    const res = await axios(options);
    return res;
  } catch (error) {
    const errors = error?.response?.data?.error?.msg;
    if (errors) {
      return { error: error?.response?.data?.error?.msg };
    } else {
      return { error: "Something Went Wrong" };
    }
  }
};
