import axios from "axios";
import { getNodeServerUrl } from "../utils/getNodeUrl";

export const getPCMSAccessTokenApi = async ( data ) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const nodeBaseUrl = getNodeServerUrl();
  const options = {
    url: `${nodeBaseUrl}/user/get-pcms-access-token`,
    method: "post",
    headers: headers,
    data: data
  };

  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
