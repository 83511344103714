import axios from "axios";
import { BASE_URL } from "../../variables";

export const DRMPlanOneAPI = async ({ newFormData }) => {

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${globalAccessToken}`,
    };
  
    const options = {
      url: `${BASE_URL}/services/apexrest/Add_DRM_Plan/`,
      method: "post",
      headers: headers,
      data: {
        "DRM": [ newFormData ]
      },
    };
    try {
      const response = await axios(options);

      if (response?.data.success === true) {
        return {
          success: true,
          data: response?.data?.message,
        };
      } else {
        return {
          success: true,
          data: response?.data?.message,
        };
      }
    } catch (err) {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",err,
      };
    }
  };