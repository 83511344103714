import axios from "axios";

import { BASE_URL } from "../../../variables";

export const getWssApi = async ({ SH2_Code__c }) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q= SELECT MIN(Id) Id, CustomerName__c, Customer_Code__c, TSI_Territory_Code__c, MIN(WSS_latitude__c) WSS_latitude__c, MIN(WSS_longitude__c) WSS_longitude__c, 
       MIN(Monthly_Tour_Plan_CheckIn__c) Monthly_Tour_Plan_CheckIn__c, Town__c, TownCode__c FROM Account WHERE recordtype.Name IN ('Customer', 'CAPScouted') AND TSI_Territory_Code__c = '${SH2_Code__c}' 
      AND DiscontinueFlag__c = false GROUP BY Customer_Code__c, CustomerName__c, Town__c, TownCode__c, TSI_Territory_Code__c`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};