import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PCMSDashboard from "../Components/PCMS/PCMSDashboard";
import PageHeader from "../Components/Common/PageHeader";

const PCMS = () => {

  return (
    <>
    <PageHeader title="PCMS" path="/" />
    <div className="container p-0">
        <PCMSDashboard/>
    </div>
    </>
  )
}

export default PCMS