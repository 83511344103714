import axios from "axios";
import { BASE_URL } from "../../variables";

export const getClusterCodeApi = async (
  SH_Code__c,
  Title,
  divisionGroup,
  salesGroup,
  Email
) => {
  let url;
  if (Title === "SH6") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH6_Code__c ='${SH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  } else if (Title === "SH5") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH5_Code__c ='${SH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  } else if (Title === "SH4") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH4_Code__c ='${SH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  }
  // else if (Title === "SH7") {
  //   url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH7_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH7_Code__c ='${SH_Code__c}' AND RecordType.Name In('Sales Hierarchy','Key Information')`;
  // }
  else if (Title === "HO Collaborator" || Title === "SH7") {
    url = `${BASE_URL}/services/data/v56.0/query?q=Select id , SH2_Code__c, SH2_Name__c ,SH3_Code__c,TXNType__c,SH6_Code__c,SH7_Code__c,SH3_Name__c  from Pidilite_Hierarchy__c where SH7_Code__c in (${SH_Code__c}) AND RecordType.Name In('Sales Hierarchy','Key Information') AND Division_Code__c ='${divisionGroup}' AND Sales_Group__c ='${salesGroup}'`;
  } else if (Title === "MH3" || Title === "RBDM Collaborator") {
    url = `${BASE_URL}/services/data/v56.0/query?q=SELECT CLUSTER_CODE__c, MIN(Id) Id, MIN(SH3_Email__c) SH3_Email__c, MIN(Cluster_Description__c) Cluster_Description__c FROM Pidilite_Hierarchy__c WHERE RecordType.Name = 'Marketing Hierarchy' AND CLUSTER_CODE__c != null  AND SH3_Email__c = '${Email}' GROUP BY CLUSTER_CODE__c`;
  }
  const options = {
    url: url,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    }else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
