import axios from "axios";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { BASE_URL } from "../../variables";

export const getRmdmWssApi = (rmdmCluster) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=Select id, CustomerName__c,WSS_Territory_Code__c,Customer_Code__c,CustomerGroup__c,Town__c,WSS_latitude__c,WSS_longitude__c  from Account where RecordType.Name in ('Customer', 'CAPScouted') and Cluster_Code__c in ('${rmdmCluster}')  AND DiscontinueFlag__c = false`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data?.records,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err.code === "ERR_NETWORK" || err.code === "ERR_BAD_REQUEST") {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
